.deviceItem {
    background-color: #4f5b5e;
    border: 2px solid #0073c1;
    color: #ffffff;
    width: 100%;
    margin: 0;
    padding: 10px 2px;
}

.devicesList {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.devicesDiv {
    width: 400px;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
}

.getButton {
    background-color: green;
}